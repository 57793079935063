<template>
  <div>服务</div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
import { useRouter } from '@/utils/compsitionHack';
export default defineComponent({
  setup() {
    const $router = useRouter();
  }
});
</script>
<style scoped></style>
